var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("canvas", {
    ref: "spinner",
    attrs: { width: "150", height: "150" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }